import React from 'react';
import { useTranslations } from 'next-intl';
import { getMediaPropsFromContentfulMedia } from 'src/utils/contentful';
import { classNames } from 'src/utils/css';
import { PREMIUM_PATH } from 'src/utils/urls';
import { Media } from 'src/tapestry/core/media';
import { FluidText } from 'src/components/common/FluidText';
import { Section } from 'src/components/layout/Section';
import { Subsection } from 'src/components/layout/Subsection';
import { Stack } from 'src/components/layout/Stack';
import { CTALinkButton } from 'src/components/common/CTALinkButton';
import { colorMidnightBg } from 'src/styles/exports.module.scss';
import { wrapper_, subheading_, cta_, media_, mobileMedia_, desktopMedia_ } from './TempHomeHeroPremium.module.scss';

const HeroBackgroundImage = ({ image, mobileImage }) => {
  if (!image) return null;

  return (
    <>
      <Media {...getMediaPropsFromContentfulMedia(mobileImage)} className={classNames(media_, mobileMedia_)} releaseAspectRatio />
      <Media {...getMediaPropsFromContentfulMedia(image)} className={classNames(media_, desktopMedia_)} releaseAspectRatio />
    </>
  );
};

export const TempHomeHeroPremium = ({ media, mobileMedia, ...rest }) => {
  const t = useTranslations('TempHomeHeroPremium');
  const _image = {
    ...media,
    description: t('Media.Alt'),
  };
  const _mobileImage = {
    ...mobileMedia,
    description: t('Media.Alt'),
  };

  return (
    <Section
      {...rest}
      animation="squeeze"
      animationTarget="content"
      background={colorMidnightBg}
      verticalSpacingCollapse="all"
    >
      <Subsection subsectionName="TempHomeHeroPremium">
        <HeroBackgroundImage image={_image} mobileImage={_mobileImage} />
        <Stack className={wrapper_}>
          <FluidText type="p" min="ws-eyebrow-sm" max="ws-eyebrow-lg">{t('Eyebrow')}</FluidText>
          <FluidText type="h1" min="ws-display-xl" max="ws-display-4xl">{t('Heading')}</FluidText>
          <FluidText type="p" className={subheading_} isBalanced>{t('Subheading')}</FluidText>
          <div className={cta_}>
            <CTALinkButton href={PREMIUM_PATH}>{t('Cta.Label')}</CTALinkButton>
          </div>
        </Stack>
      </Subsection>
    </Section>
  );
};
